@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url(./Inter-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url(./Inter-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url(./Inter-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url(./Inter-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: url(./Inter-Light.ttf) format("truetype");
}
